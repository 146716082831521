@import url('https://fonts.googleapis.com/css2?family=Yusei+Magic&display=swap');

.App {
  text-align: center;
  display: flex;
  padding: 1rem 1rem 1rem 1rem;
  box-sizing: border-box;
}

img {
    display: block;
    max-height: 100%;
    max-width: 100%;
}

h2 {
    font-family: "Yusei Magic", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.logo {
    display: block;
    height: auto;
    width: 350px;
    margin: auto;
    -webkit-filter: drop-shadow(5px 5px 7px rgba(255, 255, 255, 0.5));
    filter: drop-shadow(5px 5px 7px rgba(255, 255, 255, 0.5));
}

.main-box {
    max-width: 80%;
    border-radius: 20px;
    box-shadow: 0rem 1.75rem 4rem 0rem rgba(0,0,0,0.25);
    background-color: rgb(255,255,255,0.5);
    margin: auto;
    transition: box-shadow 0.25s ease;
}

.main-box:hover {
    box-shadow: 0rem 1.75rem 4rem 0rem rgba(0,0,0,0.50);
}


.inner {
    --padding-horizontal: 2rem;
    --padding-vertical: 1.5rem;
    padding: var(--padding-vertical) var(--padding-horizontal);
    box-sizing: border-box;
}

.buttons {
    display: flex;
    justify-content: center;
    letter-spacing: 0;
    padding: 0;
    gap: 0.625rem;
    flex-direction: column;
    flex-wrap: wrap;
    list-style: none;
    max-width: 100%;
}

.button {
    display: inline-flex;
    justify-content: center;
    width: 100%;
    background-image: linear-gradient(72deg, rgba(0,0,255,0.4) 75%, transparent 100%);
    border: none;
    border-radius: 15px;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    transition:  background-image 2s ease-in-out;
}

.button:hover {
    background-image: linear-gradient(72deg, rgba(0,0,255,0.4) 50%, transparent 100%);
}
