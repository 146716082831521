* {
  box-sizing:border-box;
}

.input-form {
  width: 100%;
  padding:10px;
  margin:10px 0;
  border: 0;
  border-radius:10px;
  box-shadow:0 0 15px 4px rgba(0,0,0,0.06);
}

textarea {
  max-width:100%;
  width:100%;
  resize: vertical;
  padding:15px;
  border-radius:15px;
  border:0;
  box-shadow:0 0 15px 10px rgba(0,0,0,0.06);
  height:150px;
}

input[type=submit] {

  /* remove default behavior */
  appearance:none;
  -webkit-appearance:none;

  /* usual styles */
  padding:20px;
  border:none;
  background-color:rgba(0,0,255,0.4);
  color:#fff;
  font-weight:600;
  border-radius:5px;
  width:100%;
  transition: background-color 0.5s;
}

input[type=submit]:hover {
  background-color:#3F51B5;
  color:#fff;
}
